@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
    --white: #ffffff;
    --whiteHover: #f4f8ff;
	--purple: #7474A3;
	--blue:#6E9EF5;
	--blueHover:#5c8be2;
	--yellow:#FFCC33;
    --green:#54B754;
    --red:#FF6666;
	--primary:#28283f;
    --lightgray:#f9f9f9;
    --lightgray2:#edf1f3;
	--primary2:#5f5997;
	--primary3:#9d9abe;
    --primary4:#9d9abe6b;
	--secondary:#55C7E9;
	--third:#55c7e9;
	--gray2: #CCC9E8;
    --halfWindowMin: 35%;
    --halfWindowMax: 65%;
}

*{
    font-family: 'Roboto';
    box-sizing: border-box;
}
html {
    scroll-behavior: smooth;
}
body{
    margin:0;
    padding:0;
}
a{
    text-decoration: none;
    color:var(--primary)
}
.Logo-Top{
    text-align: center;
    margin-top:30px;
}
.Login-Container-Outer .Logo-Top{
    display:none;
}
.Title{
    font-size: 28px;
    text-align: center;
    font-weight: 900;
    color: var(--primary);
    letter-spacing: -0.8px;
}
.Title.Title-With-Back .empty{
    width: 28px;
    height: 28px;
}
.Title.Title-With-Back{
    width:100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Sm-Title{
    text-align: center;
    font-weight: 500;
    line-height: 24px;
    font-size: 16px;
    margin-top: 40px;
    margin-bottom:10px;
    color: var(--blue);
}
.Sm-Title-With-Arrow{
    display: flex;
    justify-content: center;
    margin-bottom: -20px;
}
.Sm-Title-With-Arrow svg{
    height:70px;
    margin-left: 10px;
    stroke: var(--blue);
}
.SubTitle{
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    font-weight: 400;
    color: var(--primary);
    letter-spacing: -0.4px;
    margin-top: 10px;
}
.SubTitle span.lighter{
    color: var(--purple)
}
.Login-Container-Outer,
.Register-Container-Outer{
    background-image: url(./bg.jpg);
    background-size: cover;
    background-position: center;
    position: fixed;
    width:100%;
    height: 100vh;
    /* background-color: var(--lightgray); */
    display: flex;
    opacity:0;
    -webkit-transition: all 0.25s ease-in-out 0s;
    transition: all 0.25s ease-in-out 0s;
}
.Register-Container-Outer{
    justify-content: center;
}
.Login-Container-Outer.active,
.Register-Container-Outer.active{
    opacity:1
}
.Login-Container{
    width: var(--halfWindowMin);
    height: 100vh;
    display:flex;
    justify-content: center;
    align-items: center;
    /* background-color: var(--white); */
}
.Register-Container{
    width:100%;
    /* max-width: 700px; */
    min-height: 100vh;
    /* display:flex;
    justify-content: center;
    align-items: center; */
    overflow-y: auto;
}
.Poster-Container{
    width: var(--halfWindowMax);
    height: 100vh;
    /* background-color: var(--lightgray); */
    /* background-image: url(./bg.jpg);
    background-size: cover;
    background-position: center; */
    position: relative;
    overflow: hidden;
    opacity:0;
    -webkit-transition: all 0.25s ease-in-out 0.125s;
    transition: all 0.25s ease-in-out 0.125s;
}
.Poster-Container.active{
    opacity:1
}
.Welcome-Text{
    position: absolute;
    top:50%;
    left:50%;
    text-align: center;
    -webkit-transform:translate(-50%, -67%);
    transform:translate(-50%, -67%);
    width:100%;
    padding:0 40px;
}
.Welcome-Text .login-logo{
    max-width:180px;
    width:100%;
    display: block;
    margin:0 auto 0px;
    opacity:0;
    -webkit-transform:translate3d(0,-20px,0);
    transform:translate3d(0,-20px,0);
    -webkit-transition:all 0.5s ease-in-out 0.75s;
    transition:all 0.5s ease-in-out 0.75s;
}
.Welcome-Text h1{
    margin:10px 0;
    text-align: center;
    font-size: 62px;
    font-weight: 900;
    letter-spacing: -1px;
    color: var(--primary);
    opacity:0;
    -webkit-transform:translate3d(0,-10px,0);
    transform:translate3d(0,-10px,0);
    -webkit-transition:all 0.5s ease-in-out 1s;
    transition:all 0.5s ease-in-out 1s;
}
.Welcome-Text h2{
    text-align: center;
    font-weight: 400; 
    opacity:0;
    -webkit-transform:translate3d(0,10px,0);
    transform:translate3d(0,10px,0);
    -webkit-transition:all 0.5s ease-in-out 1.25s;
    transition:all 0.5s ease-in-out 1.25s;
}
.Welcome-Text.active .login-logo,
.Welcome-Text.active h1,
.Welcome-Text.active h2{
    opacity:1;
    -webkit-transform:translate3d(0,0px,0);
    transform:translate3d(0,0px,0);
}
.Login-Box{
    border-right: 1px solid #dedede;
    border-left: 1px solid #dedede;
    max-width: 500px;
    width:100%;
    height:100vh;
    flex-direction:column;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* -webkit-box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.11);
    -moz-box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.11);
    box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.11); */
    /* padding:20px; */
    /* border-radius: 20px; */
    position: relative;
    z-index: 1;
    background-color: #fff;
    -webkit-transition: all 1s cubic-bezier(.17,.67,.55,1.09) 0.75s;
    transition: all 1s cubic-bezier(.17,.67,.55,1.09) 0.75s;
    -webkit-transform:translate3d(0,-10px,0);
    transform:translate3d(0,-10px,0);
    opacity:0
}
.Register-Box{
    border-right: 1px solid #dedede;
    border-left: 1px solid #dedede;
    max-width: 600px;
    margin:0 auto;
    width:100%;
    min-height:100vh;
    display: flex;
    justify-content: space-between;
    flex-direction:column;
    position: relative;
    z-index: 1;
    background-color: #fff;
    -webkit-transition: all 1s cubic-bezier(.17,.67,.55,1.09) 0.25s;
    transition: all 1s cubic-bezier(.17,.67,.55,1.09) 0.25s;
    -webkit-transform:translate3d(0,-10px,0);
    transform:translate3d(0,-10px,0);
    opacity:0
}
.Login-Box.active,
.Register-Box.active{
    -webkit-transform:translate3d(0,0,0);
    transform:translate3d(0,0,0);
    opacity:1
}

.Header-Register{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top:30px;
}
.Header-Register .Logo-Top{
    margin-top:0px;
}
.Header-Register .Logo-Top svg{
    width:100px
}
.Header-Register .primary-button{
    max-width: 100px;
}
.Login-Box > div{
    max-width: 400px;
    width:100%;
    padding:20px;
}
.Register-Box > div{
    max-width: 480px;
    width:100%;
    padding:20px;
    margin:0 auto
}
.Register-Swiper .swiper-slide{
    opacity: 0;
    -webkit-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}
.Register-Swiper .swiper-slide.swiper-slide-active{
    opacity: 1;
}
@media only screen and (min-width: 992px) {
    .Login-Box .Title{
        margin-top:0
    }
}
.errorText{
    font-size: 13px;
    color:var(--red)
}
.Input-Label{
    margin-bottom: 10px;
    display: block;
    font-size: 14px;
}
.Input-Label.lighter{
    color: var(--primary3)
}
.Double-Label{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.text-input-container{
    background-color: var(--lightgray2);
    display: flex;
    justify-content:space-between;
    align-items:center;
    flex-direction:row;
    min-height:52px;
    font-size: 15px;
    border-radius: 8px;
    border-style:solid;
    border-width: 1px;
    border-color:var(--lightgray2)
}
.text-input-container.text-input-container-div{
    background-color: transparent;
    border-radius: 0px;
    border-style:solid;
    border-width: 0px;
    border-color: transparent;
    border-bottom:1px solid var(--gray2)
}
.text-input-error{
    border-color:var(--red)
}
.text-input{
    height:50px;
    flex:1; 
    width:100%;
    border:0;
    background-color:transparent;
    font-size: 15px;
}
div.text-input{
    display: flex;
    align-items: center;
    padding-left:0px;
    padding-right:0px;
    font-weight: 500;
}
.seperator-xxxxxl{
    margin-top:60px
}
.seperator-xxxxl{
    margin-top:50px
}
.seperator-xxxl{
    margin-top:40px
}
.seperator-xxl{
    margin-top:30px
}
.seperator-xl{
    margin-top:25px
}
.seperator-l{
    margin-top:20px
}
.seperator-m{
    margin-top:15px
}
.seperator-s{
    margin-top:10px
}
.toRegisterLink{
    font-size:14px;
    margin:0 auto;
    display: block;
    text-align: center;
}
.primary-button{
    padding:15px;
    background-color: var(--blue);
    border-radius:8px;
    height:52px;
    display:flex;
    justify-content: center;
    align-items: center;
    color:#fff;
    font-size:16px;
    font-weight: 700;
    flex:1;
    width: 100%;
    border:0px;
    cursor: pointer;
    -webkit-transition: all 0.125s ease-in-out;
    transition: all 0.125s ease-in-out;
}
.primary-button:hover{
    /* -webkit-box-shadow: 0px 4px 17px 0px rgba(110,158,245,0.5);
    -moz-box-shadow: 0px 4px 17px 0px rgba(110,158,245,0.5);
    box-shadow: 0px 4px 17px 0px rgba(110,158,245,0.5);
    -webkit-transform:translate3d(0,-3px,0);
    transform:translate3d(0,-3px,0); */
    background-color: var(--blueHover);
}
.primary-button.outlined-button{
    color: var(--blue);
    border:1px solid var(--blue);
    background-color: var(--white);
    border-radius:5px;
}
.primary-button.sm-button{
    max-height:36px;
    font-size:14px;
}
.primary-button.md-button{
    max-height:42px;
    font-size:14px;
}
.primary-button.sm-button:hover{
    /* -webkit-box-shadow: 0px 2px 9px 0px rgba(110,158,245,0.3);
    -moz-box-shadow: 0px 2px 9px 0px rgba(110,158,245,0.3);
    box-shadow: 0px 2px 9px 0px rgba(110,158,245,0.3);
    -webkit-transform:translate3d(0,-2px,0);
    transform:translate3d(0,-2px,0); */
}
.primary-button.outlined-button:hover{
    background-color: var(--whiteHover);
}
.Circular-Button{
    -webkit-transition: all 0.125s ease-in-out;
    transition: all 0.125s ease-in-out;
    border-radius: 200px;
}
.Circular-Button:hover{/*
    -webkit-box-shadow: 0px 4px 17px 0px rgba(110,158,245,0.5);
    -moz-box-shadow: 0px 4px 17px 0px rgba(110,158,245,0.5);
    box-shadow: 0px 4px 17px 0px rgba(110,158,245,0.5);
    -webkit-transform:translate3d(0,-3px,0);
    transform:translate3d(0,-3px,0); */
    background-color: var(--whiteHover);
}

.button.selected,
.button.selected:hover{
    background: var(--purple);
    border-color: var(--purple);
    color: var(--white)
}

.LottieLogin{
    position: absolute;
    bottom: -10.3vw;
    /* width: var(--halfWindowMax); */
    width:100%;
    max-width: 1700px;
    right:0;
    z-index: 0;
    /* background-color: var(--lightgray) ;    */
    -webkit-transition: all 1s cubic-bezier(.17,.67,.55,1.09) 0.5s;
    transition: all 1s cubic-bezier(.17,.67,.55,1.09) 0.5s;
    -webkit-transform:translate3d(0,10px,0);
    transform:translate3d(0,10px,0);
    opacity:0
}
.LottieLogin.active{
    -webkit-transform:translate3d(0,0,0);
    transform:translate3d(0,0,0);
    opacity:1
}
.bubbles-container{
    position: relative;
    z-index: 0;
    top:0;
    left:0;
}
.bubbles-container div{
    position: absolute;
    border-radius:100%;
    -webkit-transition: transform 1s cubic-bezier(.17,.67,.55,1.09);
    transition: transform 1s cubic-bezier(.17,.67,.55,1.09);
    -webkit-transform:scale3d(0,0,0);
    transform:scale3d(0,0,0);
}
.bubbles-container.active div{
    position: absolute;
    border-radius:100%;
    -webkit-transform:scale3d(1,1,1);
    transform:scale3d(1,1,1)
}
.bubbles-container div:nth-child(4){
    width:500px;
    height:500px;
    background: var(--yellow);
    top:-400px;
    right:13vw;
    -webkit-transition-delay:0s;
    transition-delay:0s;
}
.bubbles-container div:nth-child(2){
    width:600px;
    height:600px;
    background: var(--red);
    top:-300px;
    right:-350px;
    -webkit-transition-delay:0.5s;
    transition-delay:0.5s;
}
.bubbles-container div:nth-child(3){
    width:500px;
    height:500px;
    background: var(--blue);
    top:-420px;
    right:3vw;
    -webkit-transition-delay:0.75s;
    transition-delay:0.75s;
}
.bubbles-container div:nth-child(1){
    width:800px;
    height:800px;
    background: var(--green);
    top:-650px;
    right:17vw;
    -webkit-transition-delay:0.25s;
    transition-delay:0.25s;
}

.Mobile-Stores-Container{
    width: 100%;
    padding:15px 20px;
    margin-top:30px;
    margin-bottom: 0px;
    /* border-top:1px solid #dedede;
    border-bottom:1px solid #dedede; */
    /* background-color:var(--lightgray2); */
    /* border-radius: 8px; */
}
.Mobile-Stores-Prompt{
    text-align: center;
    font-weight: 900;
}
.Mobile-Stores{
    margin-top:12px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Mobile-Stores a{
    display: block;
    margin:0 2.5px
}
.Mobile-Stores img{
    display: block;
    width: 100%;
    max-width: 170px;
}
.Bottom-Links{
    margin-top:30px;
    margin-bottom:30px;
    text-align: center;
}
.Bottom-Links > *{
    font-size: 13px;
    margin:0 4px;
}
.Bottom-Links > a{
    text-decoration: underline;
}
.Login-Box > .Footer-Bottom{
    width: 100%;
    max-width: 100%;
    padding:0;
    border-top: 1px solid #dedede;
    background-color:var(--lightgray2);
    /* background-color: #ececec8c; */
}
.Login-Swiper{
    height:100px;
    max-width:450px;
    margin:40px auto 0px;
    /* padding:20px; */
    /* background-color: #fff; */
    border-radius: 8px;
    -webkit-transition:all 0.5s ease-in-out 1s;
    transition:all 0.5s ease-in-out 1s;
    opacity:0
}
.Login-Swiper.active{
    opacity:1
}
.Login-Swiper .swiper{
    max-width:100%;
    height: 100%;
}
.Login-Swiper .Slide-Content{
    line-height: 28px;
    text-align: left;
    display: flex;
    align-items: center;
}
.Login-Swiper .Slide-Content div{
    min-width:100px;
    height:100px;
    background-color: #fff;
    margin-right:20px;
    border-radius:10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border:1px solid #dedede;
    overflow: hidden;
}
.Login-Swiper .Slide-Content img{
    width:100px;
    height:100px;
}

.Image-Picker canvas {
    border-radius:100px;
    border:1px solid var(--primary3);
    margin-bottom:10px;
}
.Pet-Cover-Image .Image-Picker canvas {
    border-radius:12px;
    border:1px solid var(--primary3);
    border-radius:0px;
    border: 0px;
    margin-bottom:10px;
}

.Pet-Profile-Image .Image-Picker canvas {
    background-color: var(--white);
    box-shadow: 0px 0px 0px 5px var(--white);
}
.Image-Picker .ImgPlaceHolder{
    cursor:pointer;
    width:130px;
    height:130px;
    border-radius:130px;
    overflow: hidden;
    display:flex;
    justify-content: center;
    align-items: center;
    border:1px solid var(--primary3);
    background-color: var(--lightgray2);
    margin:0 auto;
    position: relative;
}
.Pet-Cover-Image .Image-Picker .ImgPlaceHolder{
    width:100%;
    height:300px;
    border-radius:12px;
    border-radius:0px;
    border: 0px;
    /* border-bottom:1px solid #dedede; */
    background-image: url(bg_colored.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-top-left-radius: 11px;
    border-top-right-radius: 11px;
    border-bottom-left-radius: 11px;
    border-bottom-right-radius: 11px;
}
.Pet-Cover-Image{
    position: relative;
    z-index: 0;
    /* overflow: hidden; */
    border-top-left-radius: 11px;
    border-top-right-radius: 11px;
    border-bottom-left-radius: 11px;
    border-bottom-right-radius: 11px;
}
.Pet-Cover-Image .Image-Picker canvas{
    border-top-left-radius: 11px;
    border-top-right-radius: 11px;
    border-bottom-left-radius: 11px;
    border-bottom-right-radius: 11px;
    margin-bottom:0;
}
.Pet-Profile-Image{
    position: relative;
    z-index: 1;
}
.Pet-Profile-Image .Image-Picker .ImgPlaceHolder{
    box-shadow: 0px 0px 0px 6px var(--white);
}
@media only screen and (max-width: 767px) {
    .Pet-Cover-Image .Image-Picker .ImgPlaceHolder{
        width:100%;
        height:300px;
    }
    .Pet-Profile-Image .Image-Picker .ImgPlaceHolder {
        box-shadow: 0px 0px 0px 5px var(--white);
    }
}
@media only screen and (max-width: 667px) {
    .Pet-Cover-Image .Image-Picker .ImgPlaceHolder{
        width:100%;
        height:220px;
    }
}
/* @media only screen and (max-width: 497px) {
    .Pet-Cover-Image .Image-Picker .ImgPlaceHolder{
        width:100%;
        height:150px;
    }
} */
.Image-Picker .ImgPlaceHolder.done{
    animation: pop 0.125s linear 1;
}
.Image-Picker .ImgPlaceHolder.Is-Cover.done{
    animation: pop_sm 0.125s linear 1;
}
@keyframes pop{
    30%  {transform: scale(0.9);}
}
@keyframes pop_sm{
    30%  {transform: scale(0.98);}
}
.Image-Picker .ImgPlaceHolder img{
    width:100%;
    height:100%;
    object-fit: cover;
    object-position: center;
}
.Image-Picker .ImgPlaceHolder > svg{
    fill: var(--blue);
    width:45px;
    height:45px;
}
.Image-Picker .ImgPlaceHolder .Hover-Camera-Profile-Img{
    width:100%;
    height:100%;
    background:rgba(0,0,0,0.3);
    position: absolute;
    display:flex;
    justify-content: center;
    align-items: center;
    opacity:0;
    -webkit-transition: opacity 0.125s ease-in-out;
    transition: opacity 0.125s ease-in-out;
}
.Image-Picker .ImgPlaceHolder .Hover-Camera-Profile-Img > svg{
    fill: var(--white);
    width:35px;
    height:35px;
}
.Image-Picker .ImgPlaceHolder:hover .Hover-Camera-Profile-Img{
    opacity:1
}
.Image-Picker .ImgAndControls{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.Image-Picker .Image-Controls-Container{
    padding:12px;
    background: var(--white);
    border:1px solid #dedede;
    border-radius:8px;
    position: relative;
    -webkit-box-shadow: 0px 2px 16px -4px rgba(0,0,0,0.24);
    -moz-box-shadow: 0px 2px 16px -4px rgba(0,0,0,0.24);
    box-shadow: 0px 2px 16px -4px rgba(0,0,0,0.24);
    /* left: 40px; */
}
.Image-Picker .Image-Controls-Container:before {
    content: '';
    position: absolute;
    top: -8px;
    /* left: 52px; */
    left: 50%; 
    -webkit-transform:translateX(-50%);
    transform:translateX(-50%);
    margin-top: 0px;
    width: 0;
    z-index: 1;
    height: 0;
    border-bottom: solid 8px #dedede;
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
}
.Image-Picker .Image-Controls-Container:after {
    content: '';
    position: absolute;
    top: -8px;
    /* left: 52px; */
    left: 50%;
    -webkit-transform:translateX(-50%);
    transform:translateX(-50%);
    margin-top: 1px;
    width: 0;
    z-index: 1;
    height: 0;
    border-bottom: solid 10px #FFF;
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
}
.Image-Controls-Container.Is-Cover{
    position: absolute;
    bottom:-80px
}
.Image-Picker .Image-Controls{
    display: flex;
    align-items: center;
}
.Image-Picker .Image-Controls div,
.Image-Picker .Image-Controls input{
    cursor: pointer;
}
.Image-Picker .Image-Controls span{
    margin-right:10px;
    font-size:13px
}
.Image-Picker .Image-Controls div{
    border:1px solid var(--primary3);
    height:27px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:0 7px;
    margin:5px 2px 0;
    border-radius:3px
}
.Image-Picker .Image-Controls div:first-child svg,
.Image-Picker .Image-Controls div:nth-child(2) svg{
    stroke:var(--primary);
    width:13px;
    height:13px;
}
.Image-Picker .Image-Controls div:nth-child(3) svg{
    stroke: var(--red)
}
.Image-Picker .Image-Controls div:nth-child(4){
    border:1px solid var(--primargreeny3);
    background:var(--green);
    color: var(--white);
    font-size:13px;
    padding:0 10px
}
.Image-Picker .Image-Controls div:nth-child(4) svg{
    stroke: var(--white);
    font-size:16px
}
.AvatarImagePickerButtonCancel,
.AvatarImagePickerButtonApply{
    width: 30px;
    height: 22px;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    margin:0 2px;
    cursor: pointer;
}
.AvatarImagePickerButtonCancel{
    background-color: var(--white);
    border:1px solid var(--primary3);
    color:var(--red);
    border-radius:3px
}
.AvatarImagePickerButtonApply{
    background-color: var(--white);
    border:1px solid var(--primary3);
    color:var(--green);
    border-radius:3px
}


.switch-wrapper > input[type="checkbox"] {
    opacity: 0;
    position: absolute;
  }
  
  .switch-wrapper > input[type="checkbox"] + .switch {
    transform: translateX(5px);
  }
  .switch-wrapper > input[type="checkbox"]:checked + .switch {
    transform: translateX(50%) translateX(-19px);
  }
  .switch-wrapper.large > input[type="checkbox"]:checked + .switch {
    transform: translateX(50%) translateX(-35px);
  }
  .switch-wrapper > input[type="checkbox"]:disabled + .switch {
    background: #ccc;
  }
  
  .switch-wrapper {
    border-radius: 20px;
    cursor: pointer;
    height: 24px;
    float: left;
    overflow: hidden;
    position: relative;
    width: 48px;
  }
  .switch-wrapper.large {
    height: 40px;
    width: 80px;
  }
  .switch-wrapper > .switch {
    color: #fff;
    display: inline-block;
    height: 100%;
    left: -100%;
    position: relative;
    transition: 100ms linear;
    width: 200%;
  }
  .switch-wrapper > .switch > .switch-handle {
    background: #fff;
    border-radius: 50%;
    display: inline-block;
    height: 14px;
    left: 50%;
    position: relative;
    top: 4px;
    width: 14px;
    z-index: 3;
  }
  .switch-wrapper.large > .switch > .switch-handle {
    height: 30px;
    width: 30px;
  }
  .switch-label {
    float: left;
    line-height: 2.5rem;
    font-size: 15px;
    /* margin-left: 10px; */
  }
  .switch-wrapper.large + .switch-label {
    line-height: 4rem;
    margin-left: 15px;
  }
.active-toggle {
    color: #fff;
    background-color: var(--green);
    border-color: var(--green);
}
.active-toggle:hover {
    color: #fff;
    background-color: #449d44;
    border-color: #398439;
}
.inactive-toggle {
    color: #fff;
    /* background-color: #d9534f; */
    background-color:#bababa;
    /* border-color: #d43f3a; */
    border-color:#aaaaaa;
}
.inactive-toggle:hover {
    color: #fff;
    /* background-color: #c9302c; */
    background-color:#a1a1a1;
    /* border-color: #ac2925; */
    border-color:#949494;
}
.Toggle-Switch{
    padding: 0px 18px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    background: var(--lightgray2);
}
.Info-Box{
    display: flex;
    /* padding:10px 6px; */
    padding: 12px 12px;
    align-items: flex-start;
    border:1px solid var(--primary4);
    margin-bottom: 10px;
    font-size:14px;
    line-height: 20px;
    border-radius: 8px;
    color:var(--primary);
}
.Info-Box svg{
    /* font-size: 26px;
    width:26px;
    min-width:26px;
    height:16px;
    margin-right:5px; */
    font-size: 36px;
    width: 36px;
    min-width: 36px;
    height: 36px;
    margin-right: 10px;
    /* stroke:var(--primary2); */
}






@media only screen and (max-width: 991px) {
    .Login-Container-Outer{
        overflow-y: auto;
        display: block;
    }
    .Login-Container{
        margin: 20px 0;
        height:auto;
    }
    .Login-Container,
    .Register-Container {
        /* position: absolute; */
        width: 100%;
    }
    .Poster-Container{
        display: none;
    }
    .Login-Box{
        height:auto;
        padding:0px;
        padding-bottom:0px;
        border:1px solid #dedede;
        border-radius:20px;
        margin: 0 15px;
        overflow: hidden;
    }
    .Login-Box > div {
        max-width: 400px;
        width: 100%;
        padding:25px;
        padding-top:0;
        padding-bottom:30px;
    }
    .Login-Container-Outer .Logo-Top{
        display:block;
    }
    .Title{
        font-size: 24px;
        font-weight: 900;
        letter-spacing: -0.4px;
        margin: 10px 0;
    }
    .Login-Box > .Mobile-Stores-Container{
        padding-top: 30px;
    }
    .Login-Box > .Footer-Bottom .Mobile-Stores-Container{
        padding: 15px 20px 0px;
        margin-top: 15px;
    }
    
    .Image-Picker .Image-Controls-Container.Is-Pet{
        left: 40px;
    }
    .Image-Picker .Image-Controls-Container.Is-Pet:before,
    .Image-Picker .Image-Controls-Container.Is-Pet:after {
        left: 52px;
        -webkit-transform:translateX(0%);
        transform:translateX(0%);
    }
}

body{
    background: var(--lightgray)
}
.App{
    min-height: 100vh;
}
.Main-Container-Outer{
    padding:0 10px;
}
.Main-Container{
    margin:75px auto;
    width:100%;
    max-width: 900px;
    /* background:var(--white);
    border:1px solid #dedede;
    border-radius: 12px; */
}
.Header-LoggedIn{
    position: fixed;
    z-index: 10;
    width:100%;
    height:60px;
    background:var(--white);
    border-bottom:1px solid #dedede;
    top:0;
    padding: 0 10px;
    -webkit-box-shadow: 0px 5px 17px -8px rgba(212, 212, 172127212, 1);
    -moz-box-shadow: 0px 5px 17px -8px rgba(212, 212, 172127212, 1);
    box-shadow: 0px 5px 17px -8px rgba(212, 212, 172127212, 1);
}
.Header-Centered{
    margin:0px auto;
    width:100%;
    height: 100%;
    max-width: 900px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.Logo-LoggedIn{
    display: inline-flex;
    margin-top: 2px;
    margin-right: 111px;
}
.Create-UserAvatar-Container{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.User-Avatar-LoggedIn{
    width:42px;
    height:42px;
    overflow: hidden;
    border-radius:100px;
    margin-left: 10px;
    cursor:pointer;
    border: 1px solid var(--primary3);
    padding:2px;
}
.User-Avatar-LoggedIn img{
    width:100%;
    height:100%;
    border-radius:100px;
    object-fit: cover;
    object-position: center;
}
.User-DropDown-Container{
    padding-top:7px;
    position: absolute;
    right:0;
    opacity:0;
    pointer-events:none;
    -webkit-transform:translate3d(0,-10px,0) scale3d(0.95,0.95,0.95);
    transform:translate3d(0,-10px,0) scale3d(0.95,0.95,0.95);
    -webkit-transition:all 0.125s ease-in-out;
    transition:all 0.125s ease-in-out;
}
.User-DropDown-Container.Show{
    opacity:1;
    pointer-events: initial;
    -webkit-transform:translate3d(0,0px,0) scale3d(1,1,1);
    transform:translate3d(0,0px,0) scale3d(1,1,1);
}
.User-DropDown{
    background:var(--white);
    border: 1px solid #dedede;
    border-radius:8px;
    padding:10px 0px;
    -webkit-box-shadow: 0px 2px 16px -4px rgba(0,0,0,0.24);
    -moz-box-shadow: 0px 2px 16px -4px rgba(0,0,0,0.24);
    box-shadow: 0px 2px 16px -4px rgba(0,0,0,0.24);
}
.User-DropDown:before{
    content:''; /* Required to display content */
    position: absolute; /* Sets the position absolute to the top div */
    top: 0;
    right: 12px;/* position the little arrow */
    margin-left: -15px;
	margin-top:0px; /* Set margin equal to border px */
    width: 0;
    z-index:1;
    height: 0;
    border-bottom: solid 8px #dedede; /* Creates the arrow pointing up, to change to a notch instead user border-top */
    border-left: solid 10px transparent; /* Creates triangle effect */
    border-right: solid 10px transparent; /* Creates triangle effect */
}
.User-DropDown:after{
    content:''; /* Required to display content */
    position: absolute; /* Sets the position absolute to the top div */
    top: 0;
    right: 12px;/* position the little arrow */
    margin-left: -15px;
	margin-top:1px; /* Set margin equal to border px */
    width: 0;
    z-index:1;
    height: 0;
    border-bottom: solid 10px #FFF; /* Creates the arrow pointing up, to change to a notch instead user border-top */
    border-left: solid 10px transparent; /* Creates triangle effect */
    border-right: solid 10px transparent; /* Creates triangle effect */
}
.User-DropDown-Btn{
    padding:10px 20px;
    display: flex;
    align-items: center;
    font-size:15px;
    cursor: pointer;
}
.User-DropDown-Btn svg{
    margin-right:15px;
    font-size:18px
}
.User-DropDown-Btn:hover{
    background:var(--lightgray2)
}
.Nav-LoggedIn a{
    padding:0 15px;
    display: inline-block;
}
.Nav-LoggedIn a > span{
    position: relative;
}
.Nav-LoggedIn a > span .Notification-Dot{
    position: absolute;
    bottom:10px;
    right:7px;
    width:12px;
    height:12px;
    background:red;
    border-radius: 100px;
    border:2px solid var(--white)
}
@media only screen and (max-width: 767px) {
    .Nav-LoggedIn a > span .Notification-Dot{
        width:10px;
        height:10px;
    }
}
.Nav-LoggedIn a:not(.active) svg path{
    fill:#dedede
}
.Nav-Create-LoggedIn.active svg path,
.Nav-Create-LoggedIn.active svg rect{
    fill:var(--white)
}
.Nav-Create-LoggedIn{
    display: flex;
    align-items: center;
    justify-content: center;
    height:42px;
    border-radius:100px;
    border:1px solid #dedede;
}
.Nav-Create-LoggedIn.active{
    border:1px solid var(--blue);
    background:var(--blue)
}
.Nav-Create-LoggedIn svg{
    margin-left:8px
}
.Nav-Create-LoggedIn span{
    font-size:15px;
    padding:0 20px 0 0px;
}
.Nav-Create-LoggedIn.active span{
    color:var(--white)
}
.Internal-Loader{
    width:100%;
    height:300px;
    display:flex;
    justify-content: center;
    align-items: center;
}
.Internal-Loader svg{
    width:42px;
    height:42px
}
.External-Loader{
    position: fixed;
    width:100vw;
    height:100vh;
    top:0px;
    left:0px;
    z-index: 1000;
    display:flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255,255,255,0.7);
}
.External-Loader svg{
    width:48px;
    height:48px
}

.PetOwnerProfilePage.Main-Container{
    border:0px;
    background: transparent;
    border-radius:0px;
}
.PetOwnerProfileCard{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.PetOwnerProfileCardBody{
    /* height:3000px; */
    flex:1;
    margin-left:17px;
}
.PetOwnerProfileCardHeader{
    width:260px;
    padding: 20px;
    top: 75px;
    position: sticky;
    background: var(--white);
    border: 1px solid #dedede;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    -webkit-box-shadow: 0px 5px 17px -8px rgba(212, 212, 172127212, 1);
    -moz-box-shadow: 0px 5px 17px -8px rgba(212, 212, 172127212, 1);
    box-shadow: 0px 5px 17px -8px rgba(212, 212, 172127212, 1);
}
.PetOwnerProfileCardImageContainer{
    margin:15px 0 20px;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.PetOwnerProfileCardImage{
    width:130px;
    height:130px;
    border-radius:130px;
    overflow: hidden;
    -webkit-box-shadow: 0px 0px 0px 5px var(--white), 0px 0px 0px 7px #dedede;
    -moz-box-shadow: 0px 0px 0px 5px var(--white), 0px 0px 0px 7px #dedede;
    box-shadow: 0px 0px 0px 5px var(--white), 0px 0px 0px 7px #dedede;
}
.Image-Picker-Container .Image-Picker .ImgPlaceHolder,
.Image-Picker-Container .Image-Picker canvas{
    -webkit-box-shadow: 0px 0px 0px 5px var(--white), 0px 0px 0px 7px #dedede;
    -moz-box-shadow: 0px 0px 0px 5px var(--white), 0px 0px 0px 7px #dedede;
    box-shadow: 0px 0px 0px 5px var(--white), 0px 0px 0px 7px #dedede;
}
.PetOwnerProfileCardImage img{
    width:100%;
    height:100%;
    object-fit: cover;
    object-position: center;
}
.PetOwnerProfileCardHeaderText{
    text-align: center;
}
.PetOwnerProfileCardHeader h1{
    font-size: 18px;
    font-weight: 900;
    margin:0 0 10px;
    color: var(--primary);
    text-align: center;
}
.PetOwnerProfileCardHeader h2{
    font-size: 14px;
    font-weight: 500;
    margin:0 0 10px;
    color: var(--purple);
    text-align: center;
}
.PetOwnerProfileCardHeader h2:nth-child(4){
    /* margin:0 0 15px; */
    color: var(--primary);
    font-weight: 400;
}
.CardBlock{
    background: var(--white);
    border: 1px solid #dedede;
    border-radius: 12px;
    padding:15px 20px;
    margin-bottom: 17px;
    -webkit-box-shadow: 0px 5px 17px -8px rgba(212, 212, 172127212, 1);
    -moz-box-shadow: 0px 5px 17px -8px rgba(212, 212, 172127212, 1);
    box-shadow: 0px 5px 17px -8px rgba(212, 212, 172127212, 1);
}
.In-Card-Block{
    padding:0px 20px;
}
/* .In-Card-Block .col-l{
    max-width: 500px;
    width:100%;
} */
.CardBlock-Nav{
    padding-left:10px;
    padding-right:10px;
    z-index: 9;
}
.CardBlock-Nav.Sticky{
    top:75px;
    position: sticky;
}
.CardBlock-Inset{
    /* padding:0px 0px 15px; */
    padding:0px 0px;
    /* overflow: hidden; */
}
.CardBlockTitleMajor{
    margin:0;
    font-size: 18px;
    font-weight: 900;
    letter-spacing: -0.2px;
    color:var(--primary);
}
.CardBlockTitle{
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0px;
    color: var(--purple);
}
.CardBlockTitleLarge{
    font-size: 24px;
    font-weight: 900;
    letter-spacing: -0.2px;
    color:var(--blue);
}
.CardBlockTitle-With-Back{
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    justify-content: flex-start;
}
/* .PetOwnerProfileCardHeader{
    width:100%;
    height:300px;
    position: relative;
    margin-bottom: 60px;
}
.PetOwnerProfileCardHeaderContent{
    position: absolute;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    bottom: -30px;
    left:0;
    margin: 0 auto;
    width: 100%;
}
.PetOwnerProfileCardImage{
    width:124px;
    height:124px;
    border-radius:124px;
    overflow: hidden;
    -webkit-box-shadow: 0px 0px 0px 5px var(--white), 0px 0px 0px 7px #dedede;
    -moz-box-shadow: 0px 0px 0px 5px var(--white), 0px 0px 0px 7px #dedede;
    box-shadow: 0px 0px 0px 5px var(--white), 0px 0px 0px 7px #dedede;
    margin-bottom:16px;
}
.PetOwnerProfileCardImageBg{
    width:100%;
    height: 260px;
    overflow: hidden;
    border-radius:6px;
    background: var(--white);
    border: 1px solid #dedede;
    border-radius: 12px;
}
.PetOwnerProfileCardImageBg img,
.PetOwnerProfileCardImage img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.PetOwnerProfileCardHeader h1{
    font-size: 24px;
    font-weight: 900;
    margin:0;
    color: var(--primary);
} */
.noselect {
    -webkit-touch-callout: none;
      -webkit-user-select: none;
       -khtml-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
}
.PetOwnerProfileCardTabs .react-tabs__tab-list{
    display: flex;
    justify-content: center;
    background: var(--white);
    border:1px solid #dedede !important;
    border-radius: 12px;
    margin: 0 0 17px !important;
    -webkit-box-shadow: 0px 5px 17px -8px rgba(212, 212, 172127212, 1), -3px -17px 0px 0px rgba(249,249,249,1), 3px -17px 0px 0px rgba(249,249,249,1) !important;
    -moz-box-shadow: 0px 5px 17px -8px rgba(212, 212, 212, 1), -3px -17px 0px 0px rgba(249,249,249,1), 3px -17px 0px 0px rgba(249,249,249,1) !important;
    box-shadow: 0px 5px 17px -8px rgba(212, 212, 212, 1), -3px -17px 0px 0px rgba(249,249,249,1), 3px -17px 0px 0px rgba(249,249,249,1) !important;
}
.PetOwnerProfileCardTabs .react-tabs__tab{
    border:0px !important;
    padding: 15px 20px !important;
    border-bottom:2px solid transparent !important;
    display: inline-flex !important;
    align-items: center;
}
.PetOwnerProfileCardTabs .react-tabs__tab span{
    margin-left:10px;
    font-size:15px
}
.PetOwnerProfileCardTabs .react-tabs__tab svg{
    font-size: 18px;
}
.PetOwnerProfileCardTabs .react-tabs__tab--selected{
    background: transparent !important;
    border-bottom:2px solid var(--blue) !important;
    color: var(--blue) !important;
}
.PetOwnerProfileCardTabs .react-tabs__tab--selected svg{
    fill: var(--blue);
    stroke: var(--blue);
}
.PetOwnerProfileCardTabs .react-tabs__tab:focus:after {
    display: none;
}
.PetOwnerProfileCardTabs .react-tabs-container{
    position: sticky;
    top: 75px;
}
.Empty-Text{
    font-size: 18px;
    font-weight: 300;
    margin:0
}
.button{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.button-group{
    display: flex;
}
.button-group-inline{
    display: inline-flex;
}
.button-group .button{
    margin-left:10px;
}
.button-group .button:first-child{
    margin-left:0px;
}

@media only screen and (max-width: 991px) {
    .PetOwnerProfileCard {
        flex-direction: column;
    }
    .PetOwnerProfileCardHeader {
        width: 100%;
        /* flex-direction: row; */
        position: static;
        justify-content: flex-start;
    }
    .PetOwnerProfileCardHeaderText{
        text-align: left;
    }
    .PetOwnerProfileCardImageContainer{
        width:auto;
        /* margin:10px 20px 10px 0; */
    }
    .PetOwnerProfileCardHeader h1,
    .PetOwnerProfileCardHeader h2{
        /* text-align: left; */
        margin-bottom:5px
    }
    /* .PetOwnerProfileCardImageContainer .Image-Picker .ImgPlaceHolder{
        width: 90px;
        height: 90px;
    }
    .PetOwnerProfileCardImageContainer .Image-Picker canvas{
        width: 90px !important;
        height: 90px !important;
    } */
    .PetOwnerProfileCardBody{
        margin:0;
        margin-top:17px;
        width:100%;
    }
}
@media only screen and (max-width: 767px) {
    .PetOwnerProfileCardTabs .react-tabs__tab span{
        display: none;
    }
    .PetOwnerProfileCardTabs .react-tabs__tab svg {
        font-size: 22px;
    }
}

/* .Health-Container{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.Health-Container .Health-Content{
    flex: 1;
    margin-right: 17px;
    width:calc(100% - 270px)
}
.Health-Container .Health-Side{
    width: 260px;
    padding: 20px;
    top: 75px;
    position: sticky;
    background: var(--white);
    border: 1px solid #dedede;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0px 5px 17px -8px rgb(212 212 255);
} */




.Calendar-Header{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    /* margin-bottom:17px; */
}
.Calendar-Header-Add-Data{
    padding: 8px 10px 8px 3px;
    /* width:96px;
    max-width:96px; */
    width: 136px;
    max-width: 136px;
    /* height:76px; */
    height:59px;
    margin-left:10px;
    flex-direction: row;
    font-size: 15px;
}
.Calendar-Header-Add-Data svg{
    font-size: 28px;
    width: 28px;
    height: 28px;
}
.Calendar-Header-Dates-Container{
    height:76px;
    /* height:93px; */
    /* width: calc(100% - 86px); */
    width: calc(100% - 146px);
    /* flex:1; */
    /* position:relative */
}
.Calendar-Header-Dates{
    height:100%;
    position:static !important;
    /* width: calc(100% - 100px); */
    padding-left: 1px !important;
    padding-right: 1px !important;
}
.Calendar-Header-Dates .swiper-slide{
    cursor: pointer;
}
.Calendar-Header-Dates .swiper-slide .Calendar-Header-Date-Container{
    /* height:76px; */
    height:59px;
    text-align: center;
    margin-bottom:0;
    /* padding: 11px 15px; */
    padding: 8px 15px;
}
.Calendar-Header-Dates .swiper-slide-thumb-active .Calendar-Header-Date-Container{
    border-color: var(--blue);
}
.Calendar-Header-Date-Day-Name{
    font-size:15px;
    display: none;
}
.Calendar-Header-Date-Day{
    font-weight: 900;
    /* font-size: 32px;
    line-height: 32px; */
    font-size: 26px;
    line-height: 26px;
    color: var(--gray2)
}
.Calendar-Header-Date-Month{
    font-size:13px
}
.Calendar-Header-Dates .swiper-slide-thumb-active .Calendar-Header-Date-Day{
    color:var(--blue)
}
@media only screen and (max-width: 767px) {
    .Calendar-Header-Dates-Container{
        width: calc(100% - 69px);
    }
    .Calendar-Header-Add-Data{
        padding: 8px 3px 8px 3px;
        width: 59px;
        max-width: 59px;
    }
    .Calendar-Header-Add-Data span{
        display: none;
    }
}


.Pet-Row-Block{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}
.Pet-Row-Profile-Photo-Block{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-right:10px;
    width:88px;
    margin-top:20px;
}
.Pet-Row-Profile-Photo-Add-Block{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-right:0px;
    width:88px;
    margin-top:20px;
}
.Pet-Row-Profile-Photo-Block:nth-child(6n){
    margin-right:0px
}
.Pet-Row-Profile-Photo-Block:nth-child(n+6){
    display: none;
}
.Pet-Row-Profile-Photo{
    border-radius: 80px;
    border:1px solid var(--primary3);
    overflow: hidden;
    width:70px;
    height:70px;
}
.Pet-Row-Profile-Photo-Add{
    border-radius: 80px;
    background-color: var(--white);
    color: var(--blue);
    border: 1px solid var(--blue);
    width:70px;
    height:70px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.Pet-Row-Profile-Photo img{
    width: 100%;
    height:100%;
    object-fit: cover;
    object-position: center;
}
.Pet-Row-Profile-Photo-Block h4{
    margin:0;
    font-size:15px;
    margin-top:10px;
    text-align: center;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
}
.Pets-Title-With-Num{
    display: inline-flex;
    align-items: center;
}
.PetsNum-Title{
    background: var(--blue);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    /* width: 20px; */
    padding: 0 8px 0 7px;
    height: 20px;
    border-radius: 20px;
    font-size: 15px;
    font-weight: 400;
    line-height: 14px;
    color: var(--white);
    margin-left:6px
}
.Card-Title-With-Link{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.CardBlock-Link-View-All{
    font-size:15px;
    font-weight: 500;
    text-decoration: underline;
    color: var(--blue);
}
.Top-Pet-Section{
    position: relative;
}
.Pet-Profile-Image{
    /* margin-top:-65px; */
    margin-top: -150px;
    width: 150px;
    /* -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    left: 50%; */
    left: 10px;
    position: absolute;
}

.SmallPetIcon{
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
    opacity:0;
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    margin-left:6px; 
    position:absolute; 
    top:0; 
    left:0;
    width:22px;
    height:22px;
}
.SmallPetIcon.show{
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity:1;
}




@media only screen and (max-width: 991px) {
    .Pet-Row-Block{
        justify-content: space-between;
    }
    .Pet-Row-Profile-Photo-Block{
        width:85px;
    }
    .Pet-Row-Profile-Photo-Add-Block{
        width:85px;
        flex:1;
        align-items: start;
        margin-left:10px;
    }
    .Pet-Row-Profile-Photo-Block:nth-child(6n){
        margin-right:10px
    }
    .Pet-Row-Profile-Photo-Block:nth-child(n+6){
        display: inline-flex;
    }
    .Pet-Row-Profile-Photo-Block:nth-child(n+9){
        display: none;
    }
}
@media only screen and (max-width: 918px) {
    .Pet-Row-Profile-Photo-Block:nth-child(n+8){
        display: none;
    }
}
@media only screen and (max-width: 828px) {
    .Pet-Row-Profile-Photo-Block:nth-child(n+7){
        display: none;
    }
}
@media only screen and (max-width: 733px) {
    .Pet-Row-Profile-Photo-Block:nth-child(n+6){
        display: none;
    }
}
@media only screen and (max-width: 639px) {
    .Pet-Row-Profile-Photo-Block:nth-child(n+5){
        display: none;
    }
}
@media only screen and (max-width: 543px) {
    .Pet-Row-Profile-Photo-Block:nth-child(n+4){
        display: none;
    }
}
@media only screen and (max-width: 448px) {
    .Pet-Row-Profile-Photo-Block:nth-child(n+3){
        display: none;
    }
}



@media only screen and (max-width: 767px) {
    .Pet-Profile-Image {
        /* margin-top: -45px; */
        margin-top: -105px;
        width: 110px;
    }
    .Pet-Profile-Image .Image-Picker .ImgPlaceHolder{
        width: 90px;
        height: 90px;
    }
    .Pet-Profile-Image .Image-Picker .ImgPlaceHolder > svg {
        width: 35px;
        height: 35px;
    }
    .Pet-Profile-Image .Image-Picker canvas{
        width: 90px !important;
        height: 90px !important;
    }
}



















@media only screen and (max-width: 767px) {
    .Logo-LoggedIn{
        margin-right:0px;
    }
    .Nav-Create-LoggedIn span{display:none;}
    .Nav-Create-LoggedIn svg{
        margin:0;
    }
    .Nav-Create-LoggedIn{
        width:42px;
    }
    .Nav-LoggedIn{
        flex:1;
        text-align: right;
        margin-right:6px;
    }
    .Nav-LoggedIn a{
        padding:0 6px;
    }
    .Nav-LoggedIn a svg{
        width:38px !important;
        height:38px !important;
    }
}
@media only screen and (max-width: 575px) {
    .Logo-LoggedIn svg{
        width: 60px !important;
        width: 70px !important;
    }
    .Nav-LoggedIn{
        margin-right:4px;
    }
    .Nav-LoggedIn a{
        padding:0 1px;
    }
    .Nav-LoggedIn a svg,
    .Nav-Create-LoggedIn,
    .User-Avatar-LoggedIn{
        /* width:36px !important;
        height:36px !important; */
        width:38px !important;
        height:38px !important;
    }
}



.CardBlocks-Cols-Container{
    display: flex;
}
.CardBlocks-Cols-Container .CardBlocks-Col-s{
    width: 260px;
}
.CardBlocks-Cols-Container .CardBlocks-Col-l{
    flex:1;
    margin-left: 17px;
}
.Add-Pet-Steps{
    position: sticky;
    top:75px
}
.Add-Pet-Steps .Add-Pet-Step{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 15px;
    padding: 5px 0;
    margin: 6px 0 10px;
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
}
.Add-Pet-Steps .Add-Pet-Step svg{
    margin-right:10px;
    font-size:20px
}
.Add-Pet-Steps .Add-Pet-Step:last-child{
    margin-bottom:5px;
}
.Add-Pet-Steps .Add-Pet-Step.active{
    color: var(--blue);
    font-weight: 600;
}
@media only screen and (max-width: 767px) {
    .CardBlocks-Cols-Container{
        display: block;
    }
    .CardBlocks-Cols-Container .CardBlocks-Col-s{
        display: none;
    }
    .CardBlocks-Cols-Container .CardBlocks-Col-l{
        flex:1;
        margin-left: 0px;
    }
}

.Select-Box{
    width:100%;
}
.Select-Box > div{
    background:transparent;
    border:0px;
}
.Select-Box .css-1s2u09g-control,
.Select-Box .css-1pahdxg-control,
.Select-Box .css-1insrsq-control{
    min-height: 52px;
    padding: 0 7px;
}
.Select-Box .css-26l3qy-menu{
    background: var(--white);
}
.css-1rhbuit-multiValue{
    background-color: var(--white) !important;
    border-radius: 5px !important;
    padding: 4px;
}
.css-1okebmr-indicatorSeparator{
    display: none;
}
.css-xb97g8,
.css-tlfecz-indicatorContainer,
.css-tlfecz-indicatorContainer{
    cursor: pointer;
}
.css-12jo7m5{
    font-size: 15px !important;
}

.react-calendar{
    border: 1px solid #dedede !important;
    border-radius: 12px;
    padding: 10px;
    -webkit-box-shadow: 0px 5px 17px -8px rgb(212 212 255);
    box-shadow: 0px 5px 17px -8px rgb(212 212 255);
}
.react-calendar__navigation{
    background: var(--lightgray2);
    border-radius: 8px;
    overflow: hidden;
    font-size: 14px;
}
.react-date-picker {
    width: 100%;
    height: 100%;
}
.react-date-picker__wrapper{
    border:0px !important;
    padding: 0 15px;
}
.react-date-picker__button,
.react-date-picker__button svg {
    color: var(--primary3);
    stroke: var(--primary3);
}
.react-date-picker__clear-button.react-date-picker__button svg{
    color: #cccccc;
    stroke: #cccccc;
}
.react-date-picker__clear-button.react-date-picker__button:hover svg{
    color: #999999;
    stroke: #999999;
}
.react-date-picker__inputGroup__input{
    font-weight: 400 !important;
}
.react-calendar__tile--now{
    background:var(--lightgray2) !important;
}
.react-calendar__tile{
    border-radius: 8px;
    font-size: 14px;
}
.react-calendar__tile--active {
    background: var(--blue) !important;
    color: var(--white) !important;
}
.react-calendar__tile--now:enabled:hover, 
.react-calendar__tile--now:enabled:focus {
    background: var(--purple) !important;
    color: var(--white) !important;
}
.react-calendar__month-view__weekdays__weekday.react-calendar__month-view__weekdays__weekday--weekend abbr,
.react-calendar__month-view__weekdays__weekday abbr{
    text-decoration: none;
    font-size: 12px;
}
.react-calendar__navigation__arrow{
    font-size: 24px;
}
input:-internal-autofill-selected {
    background-color: var(--lightgray2) !important;
}